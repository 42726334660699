.bond-asset-table {
  width: 100%;
  height: 100%;
  .bond-asset-header {
    height: 40px;
    width: 100%;
    background-color: #e5e5e5;
    display: flex;
    padding: 0 4vh;
    h6 {
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      width: calc(100% / 7);
      font-size: 85%;
      font-weight: 600;
      &:last-child {
        justify-content: flex-end;
      }
      &:nth-child(7) {
        justify-content: center;
      }
    }
  }
  .bond-asset-body {
    height: calc(100vh - 350px);
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .empty-list {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bond-earning-loading {
      height: 70px;
      width: 100%;
      display: flex;
      padding: 0 4vh;
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;
      h6 {
        font-size: 90%;
        width: calc(100% / 7);
        display: flex;
        align-items: center;
        button {
          font-size: 80%;
          display: flex;
          align-items: center;
          height: 40%;
          width: 70%;
          background-color: #f1f1f1;
          border: 0;
          font-weight: 500;
          animation: glow-dim 1.5s infinite;
          img {
            height: 18px;
            width: 18px;
          }
        }
        &:nth-child(2) {
        }
        &:nth-child(7) {
          justify-content: center;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
    .single-transaction {
      height: 70px;
      width: 100%;
      display: flex;
      padding: 0 4vh;
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;
      h6 {
        font-size: 90%;
        width: calc(100% / 7);
        display: flex;
        align-items: center;

        img {
          height: 20px;
          width: 20px;
          margin-right: 10px;
          border-radius: 50%;
        }
        button {
          font-size: 80%;
          display: flex;
          align-items: center;
          padding: 5px 10px;
          background-color: #f1f1f1;
          border: 0;
          font-weight: 500;
          img {
            height: 18px;
            width: 18px;
          }
        }
        &:nth-child(2) {
          button {
            margin-left: 10px;
            font-size: 65%;
            background-color: transparent;
            border: 1px solid #e5e5e5;
            &:hover {
              transform: scale(1.1);
              transition: 0.3s;
            }
          }
        }
        &:nth-child(7) {
          justify-content: center;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  .bond-asset-footer {
    background-color: #1f304f;
    height: 50px;
    display: flex;
    padding: 0 4vh;
    padding-right: 0;
    align-items: center;
    justify-content: space-between;
    h6 {
      color: #ffffff;
      margin-bottom: 0;
      margin-top: 0;
      &:last-child {
        height: 100%;
      }
    }
    button {
      margin-top: 0;
      background-color: transparent;
      border: 0;
      width: 80px;
      height: 50px;
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.split-affected {
  .bond-asset-header {
    h6 {
      width: calc(100% / 4);
      &:nth-child(3) {
        justify-content: center;
      }
      &:last-child {
        justify-content: center;
      }
    }
  }
  .bond-asset-body {
    .single-transaction {
      h6 {
        width: calc(100% / 4);
        &:nth-child(3) {
          justify-content: center;
        }
        &:last-child {
          justify-content: center;
        }
      }
    }
  }
}
