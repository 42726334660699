.mypaths {
  width: 100%;
  height: 100%;

  .mypaths-menu {
    width: 100%;
    height: 4rem;
    padding: 12px 30px;
    display: flex;
    gap: 2.5vw;
    border-bottom: 0.5px solid rgb(229, 229, 229);

    .each-mypath-menu {
      padding: 10px 30px;
      border-radius: 35px;
      font-weight: 400;
      font-size: 15px;
      color: #1f304f;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .mypaths-content {
    width: 100%;
    height: calc(100% - 4rem);

    .mypathsNav {
      width: 100%;
      height: 2.5rem;
      border-bottom: 0.5px solid #e5e5e5;
      display: flex;
      align-items: center;

      .mypaths-name-div {
        width: 30%;
        display: flex;
        align-items: end;
        padding-left: 35px;
        font-weight: 400;
        font-size: 0.9rem;
      }

      .mypaths-description-div {
        width: 50%;
        display: flex;
        align-items: end;
        padding-left: 35px;
        font-weight: 400;
        font-size: 0.9rem;
      }

      .mypathsName {
        width: 25%;
        display: flex;
        padding-left: 35px;
        font-weight: 500;
        font-size: 0.8rem;
      }

      .mypathsCountry {
        width: 25%;
        display: flex;
        font-weight: 500;
        font-size: 0.8rem;
        padding-left: 1rem;
      }

      .mypathsMicrosteps {
        width: 25%;
        display: flex;
        font-weight: 500;
        font-size: 0.8rem;
        padding-left: 1rem;
      }
    }

    .mypathsScroll-div {
      width: 100%;
      height: calc(100% - 2.5rem);
      overflow-y: scroll;

      .each-mypaths-data {
        width: 100%;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        font-size: 0.8rem;
        cursor: pointer;
        transition: all ease 0.2s;

        .each-mypaths-name {
          width: 30%;
          padding: 2rem 0 2rem 35px;
          line-height: 1.7;
          font-size: 0.9rem;
        }

        .each-mypaths-desc {
          width: 50%;
          padding: 2rem 0 2rem 0;
          font-weight: 300;
          padding-left: 35px;
          line-height: 1.8;
          // text-transform: none;
          padding-right: 1rem;
        }

        &:hover {
          background: rgba(237, 237, 237, 0.368627451);
        }
      }

      .each-mypaths-data1 {
        width: 100%;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        padding: 2rem 0 8rem 0;
        cursor: pointer;
        transition: all ease 0.2s;

        .each-mypaths-detail {
          width: 100%;
          display: flex;
          align-items: center;

          .each-mypathsName {
            width: 25%;
            display: flex;
            align-items: center;
            padding-left: 35px;
            font-weight: 500;
            font-size: 0.9rem;
            gap: 10px;
          }

          .each-mypathsCountry {
            width: 25%;
            display: flex;
            font-weight: 500;
            font-size: 0.9rem;
            padding-left: 1rem;
          }

          .each-mypathsMicrosteps {
            width: 25%;
            display: flex;
            font-weight: 500;
            font-size: 0.9rem;
            padding-left: 1rem;
          }
        }

        .each-mypaths-desc {
          width: 100%;
          padding: 3rem 35px 0 35px;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .each-mypaths-desc-txt {
            font-size: 0.9rem;
            font-weight: 500;
          }

          .each-mypaths-desc-txt1 {
            line-height: 1.8;
            font-size: 0.8rem;
            font-weight: 300;
            text-transform: none;
          }
        }

        &:hover {
          background: rgba(237, 237, 237, 0.368627451);
        }
      }
    }

    .viewpath-container {
      width: 100%;
      height: 100%;
      padding: 2rem 35px;
      color: #1f304f;
      overflow-y: scroll;

      .viewpath-top-area {
        width: 100%;
        height: 8rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;

        .viewpath-bold-text {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .viewpath-des {
          font-size: 0.8rem;
          font-weight: 300;
          line-height: 1.7;
        }

        .viewpath-goBack-div {
          position: absolute;
          top: 0;
          right: 0;
          font-weight: 600;
          text-decoration: underline;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .viewpath-steps-area {
        display: flex;
        width: 100%;
        gap: 0 4rem;
        flex-wrap: wrap;

        .viewpath-each-j-step {
          width: calc((100% - 8rem) / 3);
          border-radius: 10px;
          border: 1px solid #e5e5e5;
          display: flex;
          flex-direction: column;
          padding: 2rem 1rem;
          gap: 2rem;
          margin-top: 2rem;
          z-index: 2;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            border: 1px solid #1f304f;
          }

          .viewpath-each-j-step-text {
            width: 100%;
            font-size: 1.1rem;
            font-weight: 500;
          }

          .viewpath-each-j-step-text1 {
            width: 100%;
            font-size: 0.8rem;
            font-weight: 300;
            line-height: 1.7;
            margin-top: -1rem;
          }

          .viewpath-each-j-amount-div {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .viewpath-each-j-amount {
              font-size: 1.1rem;
              font-weight: 500;
            }
          }
        }

        .viewpath-relative-div {
          position: relative;

          .viewpath-j-arr-div {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-self: center;
            align-items: center;
            left: calc(100% + 1rem);
            z-index: 1;
            cursor: default;
          }
        }
      }
    }
  }
}
