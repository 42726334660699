.mob-single-directory-productpage {
  width: 100vw;
  height: 100vh;

  .mob-directory-sp-content {
    width: 100%;
    height: calc(100% - 4.5rem);
    overflow-y: scroll;

    .mob-directory-sp-cover-pic {
      width: 100%;
      height: 10rem;
      position: relative;
    }

    .mob-directory-sp-img-box {
      display: flex;
      height: 108px;
      justify-content: space-between;
      align-items: center;
      margin-top: -53px;
      padding: 0 15px;

      .mob-directory-sp-img {
        border-radius: 10px;
        height: 108px;
        width: 108px;
        z-index: 3;
        border: 1px solid #e5e5e5;
      }

      .mob-directory-sp-buy {
        color: white;
        background: #334480;
        padding: 10px 3rem;
        z-index: 3;
      }
    }

    .mob-directory-sp-name {
      font-size: 25px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 30px;
      color: #5f6163;
      display: flex;
      padding: 0 15px;
      justify-content: start;
    }

    .mob-directory-sp-work {
      color: #5f6163;
      display: flex;
      justify-content: start;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      min-height: 4rem;
      padding: 7px 15px 30px 15px;
    }

    .mob-directory-sp-gap-box {
      width: 100%;
      height: 0.5rem;
      background: rgba(237, 237, 237, 0.37);
    }

    .mob-directory-sp-about {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 2.5rem 15px;
      gap: 2rem;
      color: #5f6163;

      .mob-directory-sp-about-text1 {
        font-size: 1.25rem;
        font-weight: 500;
      }

      .mob-directory-sp-about-text2 {
        font-size: 0.9rem;
        font-weight: 300;
        line-height: 1.5;
      }

      .mob-directory-sp-about-bt-div {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .mob-directory-sp-details {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .bt-text1 {
            font-size: 1rem;
            font-weight: 300;
          }

          .mob-directory-sp-billing-types {
            width: 100%;
            display: flex;
            gap: 4%;
            align-items: center;

            div {
              width: 48%;
              padding: 13px 0;
              font-size: 0.8rem;
              font-weight: 300;
              text-align: center;
            }
          }
        }
      }
    }

    .mob-directory-sp-grace-div {
      width: 100%;
      display: flex;
      padding: 2rem 15px;
      align-items: center;
      justify-content: space-between;

      div {
        font-size: 1rem;
        font-weight: 300;
      }
    }

    .mob-directory-sp-gap-box1 {
      width: 100%;
      height: 3rem;
      background: rgba(237, 237, 237, 0.37);
    }
  }
}
