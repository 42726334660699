.loginPage-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;

  .loginPage-leftSide {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50%;
    flex-direction: column;

    .login-logo {
      width: 40%;
      padding-bottom: 6%;
      margin-bottom: 40px;
    }

    input {
      font-weight: 500;

      &::placeholder {
        opacity: 0.5;
      }
    }

    .login-input1 {
      width: 50%;
      height: 7%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ccc;
      border-radius: 35px;
      padding: 10px 23px;
      margin-bottom: 2%;
      font-size: 1.9vh;
    }

    .login-input2 {
      width: 50%;
      height: 7%;
      border: 1px solid #ccc;
      border-radius: 35px;
      padding: 10px 23px;
      margin-bottom: 2%;
      font-size: 1.9vh;
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        border: none;
        width: 85%;
        font-weight: 500;

        &::placeholder {
          opacity: 0.5;
        }
      }
    }

    .forgot-password-div {
      font-size: 1.9vh;
      width: 50%;
      font-weight: 200;
      display: flex;
      justify-content: flex-end;

      div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          font-weight: 600;
        }
      }
    }

    .login-Btn {
      background: #334480;
      width: 50%;
      padding: 2%;
      color: #fff;
      font-weight: 700;
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8%;
      font-size: 1.9vh;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

    .google-Btn {
      font-weight: 600;
      border-radius: 35px;
      background: #fff;
      border: 1px solid #e5e5e5;
      margin-top: 24px;
      width: 50%;
      padding: 2%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.9vh;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.05);
      }

      img {
        width: 2vh;
        height: 2vh;
        margin-right: 4%;
      }
    }

    .register-div {
      width: 50%;
      padding-top: 4%;
      display: flex;
      justify-content: center;

      p {
        margin: 0;
        font-size: 1.9vh;
        font-weight: 200;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .loginPage-rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50%;
    height: 100%;
    padding: 1rem;

    .img-div {
      width: 100%;
      height: 100%;
      background-image: url(../../static/images/loginpic.svg);
      background-position: 0;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 35px;
    }
  }
}
