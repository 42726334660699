.postLoginPage {
  width: 100%;
  height: 100%;

  .postLogin-navbar {
    width: 100%;
    height: 4rem;
    padding: 0 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: flex;
    }

    .menu-items {
      display: flex;
      gap: 2rem;
      align-items: center;
      font-size: 0.9rem;

      p {
        margin: 0;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .btns-div {
      display: flex;
      gap: 1rem;

      .gs-Btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: #334480;
        padding: 0.7rem 1.5rem;
        font-size: 0.8rem;
      }

      .business-btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: #100f0d;
        padding: 0.5rem 1.5rem;
      }

      div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .color-box {
    width: 100%;
    height: 1rem;
    background: #334480;
  }
}
