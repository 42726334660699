.servicepage {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .servicepage-content {
    width: 100%;
    height: calc(100% - 5rem);
    background: rgba(237, 237, 237, 0.37);
    position: relative;
    overflow-y: scroll;

    .servicepage-top-div {
      width: 100%;
      height: 8rem;
      padding: 0 8rem;
      position: sticky;
      background: rgba(237, 237, 237, 0.37);
      z-index: 3;
      transition: all ease 0.2s;

      .servicepage-hiding-div {
        width: 100%;
        height: calc(60% + 4rem);
        position: sticky;
        top: 5px;
        transition: all ease 0.2s;

        .servicepage-static-div {
          width: 100%;
          height: calc(100% - 4rem);
          background: #334480;
          display: flex;
          color: #ffffff;
          justify-content: center;
          align-items: center;
          font-size: 62px;
          font-weight: 500;
          border-bottom-left-radius: 35px;
          border-bottom-right-radius: 35px;

          div {
            visibility: hidden;
          }
        }

        .servicepage-search-container {
          position: absolute;
          width: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          border: 0.5px solid #e5e5e5;
          border-radius: 35px;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          height: 4rem;
          padding: 0.5rem;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          input {
            border: none;
            height: 100%;
            width: 75%;
            padding-left: 1rem;
            font-size: 1rem;
            border-top-left-radius: 35px;
            border-bottom-left-radius: 35px;

            &::placeholder {
              color: #65686d;
              opacity: 0.5;
              font-weight: 500;
            }
          }

          .servicepage-search-btn {
            background: #334480;
            border-radius: 35px;
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all ease 0.2s;

            img {
              width: 50%;
            }

            &:hover {
              img {
                transform: scale(1.02);
              }
            }
          }
        }
      }

      .servicepage-sort-div {
        display: flex;
        width: 50%;
        margin: auto;
        height: 4rem;
        gap: 1rem;
        align-items: center;

        .servicepage-sort-txt {
          font-size: 15px;
          font-weight: 500;
        }

        .servicepage-options {
          display: flex;
          gap: 1rem;
          height: 75%;

          div {
            background: rgba(237, 237, 237, 0.37);
            font-size: 15px;
            padding: 0.5rem 1.5rem;
            border-radius: 35px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }

    .servicepage-bottom-div {
      width: 100%;
      height: calc(100% - 8rem);
      background: rgba(237, 237, 237, 0.37);
      padding: 1rem 6vw 0;

      .servicepage-scroll-div {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding: 0 1vw 2rem 1vw;

        .services-txt {
          font-size: 30px;
          font-weight: 500;
          height: 2rem;
        }
  
        .services-div {
          width: 100%;
          // height: calc(100% - 2rem);
          display: flex;
          flex-wrap: wrap;
          gap: 1.25%;
  
          .each-services {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            margin-top: 1.75rem;
            justify-content: space-between;
            width: calc(97.5% / 3);
            min-height: 31vh;
            background: #ffffff;
            border-radius: 15px;
            padding: 2rem;
            border: 0.5px solid #e5e5e5;
            margin-right: 0;
            cursor: pointer;
            transition: all ease 0.2s;
  
            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
  
            .services-img-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
  
              .services-name {
                font-size: 25px;
                font-weight: 500;
                display: flex;
                justify-content: center;
              }
  
              .services-img {
                height: 50px;
                width: 50px;
              }
            }
  
            .services-work {
              color: #1f304f;
              display: flex;
              font-size: 16px;
              font-weight: 300;
              line-height: 25px;
              min-height: 4rem;
            }
  
            .services-amount {
              color: #1f304f;
              font-weight: 400;
              line-height: 18px;
              display: flex;
              align-items: baseline;
            }
          }
        }
      }

    }

    .service-container1 {
      position: absolute;
      right: -100%;
      width: 50%;
      height: 100%;
      border-left: 0.5px solid #e5e5e5;
      border-top: 0.5px solid #e5e5e5;
      top: 0;
      z-index: 17;
      transition: all ease 0.5s;
    }

    .overlay1 {
      position: fixed;
      inset: 0;
      z-index: 16;
    }
  }
}

//mobile view
.mob-service-content {
  width: 100%;
  height: calc(100% - 4.5rem);
  overflow-y: scroll;
  background: rgba(237, 237, 237, 0.37);

  .mob-top-div-service {
    width: 100%;
    height: 25%;
    position: sticky;
    top: -59px;
    z-index: 3;
    transition: all ease 0.2s;

    .mob-hiding-div-service {
      width: 100%;
      height: calc(68% + 4rem);
      position: sticky;
      transition: all ease 0.2s;

      .mob-static-div-service {
        width: 100%;
        height: calc(100% - 4rem);
        background: #334480;
        display: flex;
        color: #ffffff;
        justify-content: center;
        align-items: center;
        font-size: 33px;
        font-weight: 500;
      }

      .mob-search-container-service {
        position: absolute;
        width: 93%;
        transform: translate(-50%, -50%);
        left: 50%;
        border: 0.5px solid #e5e5e5;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        height: 4rem;
        padding: 0.5rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        input {
          border: none;
          height: 100%;
          width: 75%;
          padding-left: 1rem;

          &::placeholder {
            color: #65686d;
            opacity: 0.5;
            font-weight: 600;
          }
        }

        .mob-search-btn-service {
          border-radius: 35px;
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.2s;

          img {
            width: 32%;
          }
        }
      }
    }
  }

  .mob-scroll-div-service {
    overflow-y: scroll;
    height: 75%;

    .mob-bottom-div-service {
      width: 100%;
      padding: 0 7vw 2rem;

      .mob-acc-txt-service {
        font-size: 30px;
        color: #5f6163;
        font-weight: 500;
        height: 2rem;
      }

      .mob-accountants-div-service {
        width: 100%;
        height: calc(100% - 2rem);
        display: flex;
        flex-wrap: wrap;
        gap: 2.75%;
        flex-direction: column;

        .mob-each-services {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-top: 1.75rem;
          justify-content: space-between;
          width: 100%;
          min-height: 31vh;
          background: #ffffff;
          padding: 2rem;
          border: 0.5px solid #e5e5e5;
          margin-right: 0;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }

          .mob-services-img-box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mob-services-name {
              font-size: 25px;
              font-weight: 500;
              color: #415c48;
              display: flex;
              justify-content: center;
            }

            .mob-services-img {
              height: 50px;
              width: 50px;
            }
          }

          .mob-services-work {
            color: #5f6163;
            display: flex;
            font-size: 16px;
            font-weight: 500;
            line-height: 25px;
            min-height: 4rem;
          }

          .mob-services-amount {
            color: #5f6163;
            line-height: 18px;
            display: flex;
            align-items: baseline;
          }
        }
      }
    }
  }
}
