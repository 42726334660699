@import "../../../static/scss/mixin";

.monthName{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    width: 180px;
    color: #18191D;
    padding-right: 14px;
  

}

.dayName{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    width: 200px;
    color: #18191D;
    padding-right: 14px;
    cursor: pointer;
    scale: 1;
    &:hover{
   transform: scale(1.03);
   transition: all ease-in-out;
    }

}

.arrowButtons{
    width: 25px; 
    height: 25px;
    cursor: pointer;
    transition: all 20ms ease-in-out;
    &:hover{
        width:27px;
        height: 27px;        
    }
}
.filterOpen{
    position: fixed;
    right: 40px;
    top:143px;
    width: 180px;
    height: 300px;
    border: 0.25px solid #18191D;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0px;
    & > div{
        padding: 0px 15px;
        &:hover{
            font-weight: 700;
            cursor: pointer;
        }
    }
}
.filterClosed{
    position: fixed;
    right: 40px;
    top:135px;
    width: 180px;
    height: 34px;
    border: 0.25px solid #18191D;
    border-radius: 5px;
    // background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 20px;
    font-weight: 700;
   cursor: pointer;
   
}
.calendarBody{

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    // height: 
}

.singeDayBody{
    display: flex;
    overflow-x: scroll;
    .singleDayCard{
        min-width: 392px;
        border-right: 0.5px solid #E7E7E7;
        padding: 30px;
        font-family: 'Montserrat';
        font-style: normal;
        line-height: 160%;
        letter-spacing: 0.002em;
        overflow-y: scroll;
       .titleHead{
        font-weight: 700;
        font-size: 20px;
        color: #18191D;
       }
       .title{
        font-weight: 800;
        font-size: 16px;
       }
       .subTitle{
        font-weight: 300;
        font-size: 10px;
       }
    }
}
    


    