.footer {
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // width: 100%;
  height: 430px;
  background-color: #334480;
  padding: 50px 138px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 0px;
}
.firstCol{
  img{
    margin-bottom: 30px;
  }
  div{
    padding-bottom: 35px;
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    span{
      font-weight: 600;
    }
  }
}
.secondCol{
  margin-top: 80px;
  margin-left: 30px;
  div{
    padding-bottom: 35px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.footerMobile{
  display: none;
}
@media only screen and (max-width: 600px) {
  .footer{
    display: none;
  }
  .footerMobile {
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // width: 100%;
    height: auto;
    background-color: #334480;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
   
   
  }
  .firstCol{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      margin-bottom: 50px;
      
    }
    div{
      padding-bottom: 35px;
      font-size: 17px;
      font-weight: 400;
      color: #fff;
      display: flex;
      span{
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
  .secondCol{
    margin-top: 40px;
    margin-left: 0px;
    div{
      padding-bottom: 35px;
      font-size: 17px;
      font-weight: 600;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
