.homepage1 {
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  .homepage-content {
    width: 100%;
    height: calc(100% - 5rem);
    overflow-y: scroll;

    .hero-content {
      width: 100%;
      height: 65%;
      display: flex;
      padding: 1rem 8rem;
      align-items: center;
      // justify-content: space-between;
      gap: 5rem;

      .hero-text-div {
        display: flex;
        flex-direction: column;

        .hero-text1 {
          font-size: calc(3vw + 8px);
          color: #334480;
          font-weight: 700;
        }

        .hero-text2 {
          font-size: 2.5rem;
          color: #334480;
          font-weight: 600;
          margin-top: 0.25rem;
        }

        .hero-text3 {
          width: 77%;
          font-size: 1.25rem;
          color: #334480;
          font-weight: 400;
          margin-top: 1.75rem;
          line-height: 1.8;
        }
      }

      .hero-img-div {
        // height: 100%;
        img {
          height: 100%;
          transform: scale(1.15);
          // border: 1px solid;
          margin-top: -1rem;
        }
      }
    }

    .input-box-container {
      height: 8rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      background: #334480;

      .input-box1 {
        height: 4rem;
        display: flex;

        input {
          border: none;
          border-radius: 5px;
          width: 30rem;
          padding: 0 2rem;
          font-weight: 500;
          font-size: 1.1rem;

          &::placeholder {
            opacity: 1 !important;
          }
        }
      }

      .createPath-btn {
        height: 4rem;
        display: flex;
        color: white;
        padding: 0 3rem;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        transition: all ease 0.2s;
        font-size: 1.1rem;
        border-radius: 5px;
        border: 1px solid #fff;
        background: #334480;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .hero-content-mob {
      width: 100%;
      height: calc(100% - 8rem);
      display: none;
      padding: 2rem 1rem;
      align-items: center;
      flex-direction: column;

      .hero-text-div-mob {
        display: flex;
        flex-direction: column;
        align-items: center;

        .hero-text1-mob {
          font-size: calc(11vw + 6px);
          color: #334480;
          font-weight: 700;
          text-align: center;
          line-height: 1.16;
        }

        .hero-text2-mob {
          font-size: calc(6vw + 2px);
          color: #334480;
          font-weight: 700;
          margin-top: 1.25rem;
          text-align: center;
        }

        .hero-text3-mob {
          color: #334480;
          font-weight: 400;
          margin-top: 2rem;
          line-height: 1.8;
          text-align: center;
        }
      }

      .hero-img-div-mob {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 85%;
        }
      }
    }

    .input-box-container-mob {
      height: 8rem;
      width: 100%;
      display: none;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      background: #334480;
      padding: 0 1rem;

      .input-box1-mob {
        height: 4rem;
        display: flex;
        width: 100%;
        position: relative;
        border-radius: 45px;
        background: white;

        input {
          border: none;
          border-radius: 45px;
          width: 75%;
          padding: 0 2rem;
          font-weight: 500;
          font-size: 1rem;
        }

        .createPath-btn-mob {
          z-index: 4;
          height: 2.5rem;
          display: flex;
          color: white;
          background: #334480;
          border-radius: 45px;
          padding: 0 2rem;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          transition: all ease 0.2s;
          font-size: 0.9rem;
          position: absolute;
          right: 1rem;
          top: 0.75rem;
        }
      }
    }

    .hiw-container {
      width: 100%;
      height: calc(100% - 8.5rem);
      padding: 4rem 8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5rem;

      .hiw-text-div {
        display: flex;
        flex-direction: column;
        align-items: center;

        .hiw-text1 {
          color: #334480;
          font-weight: 700;
          font-size: calc(2.5vw + 8px);
        }

        .hiw-text2 {
          font-size: 1.75rem;
          font-weight: 500;
          color: #100f0d;
          margin-top: 1.5rem;
        }

        .hiw-text2-mob {
          font-size: 1.75rem;
          font-weight: 500;
          color: #100f0d;
          margin-top: 1.5rem;
          display: none;
        }
      }

      .hiw-options {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: space-between;

        .each-hiw-option {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          z-index: 2;
          background: white;
          cursor: pointer;
          transition: all ease 0.2s;

          .img-border {
            border: 1px solid #e7e7e7;
            border-radius: 50%;
            padding: 2rem;
          }

          .each-hiw-option-name {
            // font-size: 0.9rem;
            font-weight: 500;
            text-align: center;
          }

          &:hover {
            .img-border {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }

            .each-hiw-option-name {
              transform: scale(1.05);
            }
          }
        }

        .centre-line {
          position: absolute;
          width: 100%;
          border: 0.5px solid #e5e5e5;
          top: 42%;
          z-index: 1;
        }
      }
    }
  }
}


.card-container{
  padding: 1rem 8rem;
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 5rem;
  div{
    // background-color: red;
  }
}
.cardLabel{
  font-size: 20px;
  font-weight: 500;
  color: #100F0D;
  margin-bottom: 1rem;
}
.cardwrapper{
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 1.5rem;
  
}
.cardTitle{
  font-size: 18px;
  font-weight: 400;
  color: #100F;
  padding-left: 11px;
  span{
    font-weight: 600;
    color: black;
  }
}
.cardSubtitle{
  font-size: 15px;
  font-weight: 400;
  padding-top: 1.2rem;
  line-height: 27px;
}
.cardBadge{
  background-color: #f5f5f5;
  padding: 17px 25px;
  border-radius: 10px;
  margin-top: 5px;
  width: fit-content;
  margin-left: 4rem;
}


@media screen and (max-width: 768px) {
  .homepage {
    .homepage-content1 {
      height: calc(100% - 4.5rem);
      overflow-y: scroll;
      .hero-content {
        display: none;
      }
      .input-box-container {
        display: none;
      }
      .hero-content-mob1 {
        display: flex;
        height: unset;
      }
      .input-box-container-mob1 {
        display: flex;
      }
      .hiw-container {
        padding: 4rem 0rem;
        align-items: center;
        height: unset;
        gap: 3rem;
        .hiw-text-div {
          .hiw-text1 {
            display: none;
          }
          .hiw-text2 {
            display: none;
          }
          .hiw-text2-mob {
            display: flex;
          }
        }
        .hiw-options {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 3rem;
          .each-hiw-option {
            width: 50%;
            justify-content: center;
            align-items: center;
            .img-border {
              width: 150px;
              height: 150px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
            }
          }
          .centre-line {
            display: none;
          }
        }
      }
    }
  }

.card-container{
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.cardLabel{
  font-size: 20px;
  font-weight: 500;
  color: #100F0D;
  margin-bottom: 1rem;
}
.cardwrapper{
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 1.5rem;
  
}
.cardTitle{
  font-size: 18px;
  font-weight: 400;
  color: #100F;
  padding-left: 11px;
  span{
    font-weight: 600;
    color: black;
  }
}
.cardSubtitle{
  font-size: 15px;
  font-weight: 400;
  padding-top: 1.2rem;
  line-height: 27px;
}
.cardBadge{
  background-color: #f5f5f5;
  padding: 17px 25px;
  border-radius: 10px;
  margin-top: 5px;
  width: fit-content;
  margin-left: 4rem;
}
.mob-hero{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  :nth-child(1){
    font-size: 30px;
    font-weight: 700;
    color: #334480;
    padding: 1rem 0px;
  }
  :nth-child(2){
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #334480;
    // padding: 1rem 0px;
    text-align: center;
  }
  img{
    margin: 2rem 0px;
  }
}
}
