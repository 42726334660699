.stepViewPage {
  width: 100%;
  height: calc(100% - 3rem);

  .stepviewNav {
    width: 100%;
    height: 3.5rem;
    border-bottom: 0.5px solid #e5e5e5;
    display: flex;

    .step-name-div {
      width: 30%;
      display: flex;
      align-items: end;
      padding-bottom: 0.5rem;
      padding-left: 35px;
      font-weight: 400;
      font-size: 0.8rem;
    }

    .step-description-div {
      width: 70%;
      display: flex;
      align-items: end;
      padding-bottom: 0.5rem;
      padding-left: 35px;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  .stepviewContent {
    width: 100%;
    height: calc(100% - 3.5rem);
    overflow-y: scroll;

    .each-sv-data {
      width: 100%;
      border-bottom: 0.5px solid #e5e5e5;
      display: flex;
      font-size: 0.8rem;
      //   cursor: pointer;
      transition: all ease 0.2s;

      .each-sv-name {
        width: 30%;
        padding: 2rem 0 2rem 35px;
        line-height: 1.6;
      }

      .each-sv-desc {
        width: 70%;
        padding: 2rem 0 2rem 0;
        font-weight: 300;
        padding-left: 35px;
        line-height: 1.6;
        text-transform: none;
        padding-right: 1rem;
      }

      &:hover {
        background: rgba(237, 237, 237, 0.368627451);
      }
    }
  }
}
