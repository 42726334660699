.journeypage {
  width: 100%;
  height: 100%;
  padding: 2rem 35px;
  overflow-y: scroll;
  color: #1f304f;

  .journey-top-area {
    width: 100%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;

    .bold-text {
      font-size: 1.5rem;
      font-weight: 500;
    }

    .journey-des {
      font-size: 0.8rem;
      font-weight: 300;
      line-height: 1.7;
    }

    .goBack-div {
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .journey-steps-area {
    display: flex;
    width: 100%;
    gap: 0 4rem;
    flex-wrap: wrap;

    .each-j-step-container {
      width: calc((100% - 8rem) / 3);
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      gap: 2rem;
      margin-top: 2rem;
      z-index: 2;
      position: relative;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        border: 1px solid #1f304f;
      }

      .each-j-stepp {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 2rem;

        .each-j-step-text {
          width: 100%;
          font-size: 1.1rem;
          font-weight: 500;
        }

        .each-j-step-text1 {
          width: 100%;
          font-size: 0.8rem;
          font-weight: 300;
          line-height: 1.7;
          margin-top: -1rem;
        }

        .each-j-amount-div {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .each-j-amount {
            font-size: 1.1rem;
            font-weight: 500;
          }
        }
      }

      .current-step-indicator {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 1rem;
        border-radius: 0px 0px 10px 10px;
        background: #334480;
        color: white;
        font-size: 0.8rem;
        font-weight: 600;
        justify-content: center;
      }

      .completed-step-indicator {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 1rem;
        border-radius: 0px 0px 10px 10px;
        background: #8ed8c6;
        color: white;
        font-size: 0.8rem;
        font-weight: 600;
        justify-content: center;
      }
    }

    .relative-div {
      position: relative;

      .j-arr-div {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-self: center;
        align-items: center;
        left: calc(100% + 1rem);
        z-index: 1;
        cursor: default;
      }
    }
  }
}
